<template>
  <div class="recruitment__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      用工招聘管理
    </div>
    <div class="content">
      <div
        class="recruit_tab"
        style="margin-bottom: 24px; display: flex; align-items: center; justify-content: space-between; width: 100%"
      >
        <a-form-model
          layout="inline"
          :model="queryParams"
          v-bind="{
            wrapperCol: { span: 18 },
            labelCol: { span: 6 },
          }"
        >
          <a-form-model-item label="项目部">
            <a-select
              ref="select"
              allowClear
              :options="Departmentlist"
              v-model="queryParams.projectDepartmentId"
              placeholder="请选择项目部"
              @change="changeDepartment"
              style="width: 220px"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item
            label="项目名称"
          ><a-select
            allowClear
            ref="select"
            :options="Projectlist"
            v-model="queryParams.projectId"
            placeholder="请选择项目名称"
            @change="changeProjectlist"
            style="width: 220px"
          ></a-select
          ></a-form-model-item>
          <a-form-model-item label="工种/专业">
            <a-select
              allowClear
              ref="select"
              :options="Positionlist"
              placeholder="请选择工种/专业"
              v-model="queryParams.demandId"
              style="width: 220px"
            ></a-select
            ></a-form-model-item>
          <a-form-model-item label="签约状态" v-if="tabVal == 4">
            <a-select
              allowClear
              :options="signStatusList"
              placeholder="请选择签约状态"
              v-model="queryParams.signStatus"
              style="width: 220px"
            ></a-select>
          </a-form-model-item>
        </a-form-model>
        <div style="float: right">
          <a-button type @click="handleReset">重 置</a-button>
          <a-button type="primary" @click="search" :disabled="!queryParams.demandId">查 询</a-button>
        </div>
      </div>
      <a-tabs type="card" animated v-model="tabVal" @change="getTab">
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.title">
          <div class="table__content">
            <p-table
              :columns="tableColumns"
              :ref="`table${item.key}`"
              :source-data="getDataApi"
              :scorll="{ y: 600 }"
              extraHeight="450"
            >
              <template slot="createTime" slot-scope="text">{{ formatTime(text) }}</template>
              <template slot="phone" slot-scope="text">{{ formatPhone(text) }}</template>
              <template slot="operation" slot-scope="text, record">
                <span
                  class="operation_btn"
                  @click="getDetail(record)"
                  v-if="tabVal == '2' || tabVal == '1'"
                >查看详情</span
                >
                <a-popconfirm
                  title="确认进一步沟通吗？"
                  v-if="tabVal == '2' || tabVal == '1'"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="communicate(record)"
                >
                  <span class="operation_btn">进一步沟通</span>
                </a-popconfirm>
                <a-popconfirm
                  title="确认移除吗？"
                  v-if="tabVal == '3'"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="deletes(record)"
                >
                  <span class="operation_btn">移除</span>
                </a-popconfirm>
                <a-popconfirm
                  title="确认录用吗？"
                  v-if="tabVal == '3'"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="employment(record)"
                >
                  <span class="operation_btn">确认录用</span>
                </a-popconfirm>
              </template>
            </p-table>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-modal v-model="visible" cancelText="取消" okText="加入待沟通" title="人员信息" @ok="communicate(obj)">
      <a-row>
        <a-col :span="24">
          <div class="recruitUse_modal">姓名：{{ obj.realName }}</div>
        </a-col>
        <a-col :span="24">
          <div class="recruitUse_modal">
            综合信用评价：
            <a-rate v-model="obj.star" disabled />
          </div>
        </a-col>
        <a-col :span="8">
          <div class="recruitUse_modal">年龄：{{ obj.age }}</div>
        </a-col>
        <a-col :span="8">
          <div class="recruitUse_modal">性别：{{ handleGetDict('sexType')[obj.sex] }}</div>
        </a-col>
        <a-col :span="8">
          <div class="recruitUse_modal">学历：{{ handleGetDict('communicateObj')[obj.education] }}</div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <div class="recruitUse_modal">联系电话：{{ formatPhone(obj.phone) }}</div>
        </a-col>
        <a-col :span="12">
          <div class="recruitUse_modal">人员类别：{{ handleGetDict('userType')[obj.type] }}</div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <div class="recruitUse_modal">工作经历：{{ handleGetDict('workObj')[obj.experience] }}</div>
        </a-col>
        <a-col :span="12">
          <div class="recruitUse_modal">基本技能：{{ obj.baseSkill }}</div>
        </a-col>
      </a-row>
      <a-row>
        <!-- <a-col :span="12">
          <div
            class="recruitUse_modal"
          >从业资格证：{{ handleGetDict('qualificationObj')[obj.qualificationCertificate] }}</div>
        </a-col> -->
        <a-col :span="12">
          <div class="recruitUse_modal">技能等级证：{{ handleGetDict('levelObj')[obj.skillLevelCertificate] }}</div>
        </a-col>
        <a-col :span="12">
          <div class="recruitUse_modal">个人特长：{{ obj.specialty }}</div>
        </a-col>
      </a-row>
    </a-modal>
    <fileDialog ref="fileDialog" :callback="handleUplateStatus" />
  </div>
</template>

<script>
import {
  employmentEmploy,
  employmentEmploycommunicate,
  employmentEmployDelete,
  employmentEmployDepartment,
  employmentEmployDetail,
  employmentEmploylist,
  employmentEmployReject,
  employmentEmploySmart,
  enterpriseEmploymentManagement,
  queryAllWbProject,
  waitCommunicateUserInfolist,
  queryToBeSignCLXYUser
} from '@/api/recruitUse'
import { Rate } from 'ant-design-vue'
import fileDialog from '@/components/common/pdf-dialog/file-dialog.vue'

const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    customRender: (t, r, i) => i + 1
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    customRender: (t, r) => r.realName || t
  },
  {
    title: '性别',
    dataIndex: 'sex',
    customRender: (t) => (t == 0 ? '男' : t == 1 ? '女' : '其他')
  },
  {
    title: '年龄',
    dataIndex: 'age',
    key: 'age'
  },
  // {
  //   title: '职业技能',
  //   dataIndex: 'baseSkill',
  //   key: 'baseSkill',
  //   customRender: (t, r) => r.baseSkill || t
  // },
  {
    title: '联系电话',
    dataIndex: 'phone',
    key: 'phone',
    scopedSlots: { customRender: 'phone' }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    title: '操作',
    width: 220,
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'Index',
  components: {
    'a-rate': Rate,
    fileDialog
  },
  data () {
    return {
      tabs: [
        {
          key: 1,
          title: '智能匹配'
        },
        {
          key: 2,
          title: '感兴趣的'
        },
        {
          key: 3,
          title: '待沟通'
        },
        {
          key: 4,
          title: '待签约'
        }
      ],
      autoExpandParent: true,
      data: [],
      columns,
      visible: false,
      tabVal: 1,
      Departmentlist: [],
      Projectlist: [],
      Positionlist: [],
      queryParams: {
        position: undefined,
        projectDepartmentId: undefined,
        demandId: undefined,
        signStatus: undefined
      },
      list: [],
      obj: {},
      contractInfo: null,
      rowItem: null,
      signStatus: {
        0: '待工人签约',
        1: '签署完成'
      },
      signStatusList: [
        { value: '0', label: '待工人签约' },
        { value: '1', label: '签署完成' }
      ],
      refreshKeys: []
    }
  },

  computed: {
    rowSelection (val) {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name
          }
        })
      }
    },
    tableColumns () {
      return this.tabVal === 4
        ? columns.reduce((arr, item, index) => {
            if (index === 6) {
              arr.push({
                title: '签约状态',
                dataIndex: 'status',
                key: 'status',
                customRender: (t) => this.signStatus[t]
              })
              return [...arr]
            }
            return [...arr, item]
          }, [])
        : columns
    }
  },
  mounted () {
    employmentEmployDepartment()
      .then((res) => {
        res.data.forEach((v) => {
          v.label = v.projectDepartment
          v.value = v.projectDepartmentId
        })
        this.Departmentlist = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    search () {
      this.refreshKeys = []
      this.$refs[`table${this.tabVal}`][0].initPage()
      this.refreshKeys.push(this.tabVal)
    },
    formatPhone (phone) {
      if (this.tabVal < 3) {
         if (!phone) return ''
          const pat = /(\d{3})\d*(\d{4})/
          return phone.replace(pat, '$1***$2')
      }
      return phone
    },
    handleReset () {
      this.$set(this, 'queryParams', {
        position: undefined,
        projectDepartmentId: undefined,
        demandId: undefined,
        signStatus: undefined
      })
      this.Projectlist = []
      this.Positionlist = []
    },
    getDataApi (params) {
      const apiObj = {
        1: employmentEmploySmart,
        2: employmentEmploylist,
        3: waitCommunicateUserInfolist,
        4: queryToBeSignCLXYUser
      }
      const fun = apiObj[this.tabVal]
      if (this.queryParams.demandId) {
        return fun({
          ...params,
          ...this.queryParams
        }).then((res) => {
            const newData = {}
          if (this.tabVal === 1) {
            newData.data = res.splice((params.pageIndex - 1) * params.pageSize, params.pageSize)
            newData.totalCount = res.length
            newData.pageIndex = params.pageIndex
            newData.pageSize = params.pageSize
          } else {
            newData.data = res.data
            newData.totalCount = res.totalCount
            newData.pageIndex = res.pageIndex
            newData.pageSize = res.pageSize
            newData.totalPages = res.totalPages
          }
          return Promise.resolve({
            ...newData,
            data: newData.data.map((item) => ({
              ...item,
              ...(item.userInfo || {}),
              name: item.name || (item.userInfo || {}).realName
            }))
          })
        })
      }
      // eslint-disable-next-line promise/param-names
      return new Promise((reslove) => {
        reslove({})
      })
    },
    Reject (item) {
      const id = {
        userId: item.userId
      }
      employmentEmployReject(id)
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deletes (item) {
      const params = {
        id: item.id
      }
      employmentEmployDelete(params)
        .then((res) => {
          if (res.success) {
            this.$message.success('操作成功')
          } else {
            this.$message.success('操作失败')
          }
          this.$refs[`table${this.tabVal}`][0].initPage()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 确认录用
    employment (item) {
      this.rowItem = item
      // const params = {
      //   contractNumber: 'LWHT',
      //   post: item.demandInfo.positionName,
      //   projectEndTime: this.formatTime(item.projectEndTime, 'YYYY-MM-DD'),
      //   projectId: item.projectId,
      //   demandId: item.demandInfo.demandId,
      //   projectLeaderId: item.projectLeaderId,
      //   projectName: item.projectName,
      //   projectStartTime: this.formatTime(item.projectStartTime, 'YYYY-MM-DD'),
      //   userId: item.userId,
      //   workerName: item.name,
      //   money: item.demandInfo.salary,
      //   status: 0,
      //   enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId,
      //   enterpriseName: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseName
      // }
      // createContract(params).then((res) => {
      //   this.contractInfo = res.data
      //   this.handleUplateStatus()
      // })
      this.handleUplateStatus()
    },
    handleUplateStatus () {
      this.$spin.show()
      return employmentEmploy({
        demandId: this.rowItem.demandInfo.demandId,
        id: this.rowItem.id,
        userId: this.rowItem.userInfo.userId
      })
        .then((res) => {
          this.$notification.success({
            message: '录用成功',
            description: '待工人和平台合同签署成功后，在考勤管理中进行查看'
          })
          this.$refs[`table${this.tabVal}`][0].initPage()
        })
        .finally(() => {
          this.$spin.hide()
        })
    },
    // 进一步沟通
    communicate (item) {
      const params = {
        userId: item.userId,
        ...this.queryParams
      }
      employmentEmploycommunicate(params)
        .then((res) => {
          if (res.errCode !== 'DuplicateKey') {
            this.$message.success('操作成功')
            this.$refs[`table${this.tabVal}`][0].initPage()
          } else {
            this.$message.warning('请勿重复操作')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getDetail (record) {
      this.visible = true
      const params = {
        userId: record.userId
      }
      employmentEmployDetail(params).then((res) => {
        this.obj = res.data
      })
    },
    // 获取列表
    changePosition (val) {
      this.queryParams.demandId = val
      this.$refs[`table${this.tabVal}`][0].initPage()
    },
    changeProjectlist (val) {
      this.Positionlist = []
      this.queryParams.demandId = undefined
      const params = {
        projectId: val
      }
      val &&
        queryAllWbProject(params).then((res) => {
          res.data.forEach((v) => {
            v.label = v.wbProject
            v.value = v.demandId
          })
          this.Positionlist = res.data
        })
    },
    // 获取项目名称
    changeDepartment (val) {
      this.queryParams.projectId = undefined
      this.queryParams.demandId = undefined
      this.Projectlist = []
      this.Positionlist = []
      const params = {
        projectDepartmentId: val
      }
      val &&
        enterpriseEmploymentManagement(params).then((res) => {
          res.data.forEach((v) => {
            v.label = v.projectName
            v.value = v.projectId
          })
          this.Projectlist = res.data
        })
    },
    getTab (tabVal) {
      this.tabVal = tabVal
      if (!this.refreshKeys.includes(tabVal)) {
        this.refreshKeys.push(tabVal)
        this.$refs[`table${tabVal}`] && this.$refs[`table${tabVal}`][0].initPage()
      }
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('./index.less');
.recruitment__wrapper {
  height: 100%;
  .content {
    height: calc(100% - 45px);
    padding: 24px;
  }
}
</style>
